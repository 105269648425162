import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./imageloader.module.css";
import apiurl from "../apiUrl";
import imageurl from "../imageurl";

function Imageloader({ activepageid }) {
    const [images, setImages] = useState([]);
    const [counterImages, setCounterImages] = useState(0);
    const [delId, setDelid] = useState(0);





    const fetchurl = apiurl + "getpagimages.php";
    const toogleurl = apiurl + "setteaserimage.php";
const toogleTeaserImage = async (imageid) =>{

    try {
        const response = await axios.post(toogleurl, {activepageid,imageid}, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {

        } else {
            console.error('Fehler bei der Anfrage:', response.status);
        }
    } catch (error) {
        console.error('Fehler beim Senden der Daten:', error);
    }
}
    const handleImage = async (imageId) => {

        const confirm = window.confirm("Möchten Sie das Bild löschen?")

        if(confirm) {

            try {
                const response = await axios.post(apiurl + 'deletepagimage.php', {imageId}, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {

                } else {
                    console.error('Fehler bei der Anfrage:', response.status);
                }
            } catch (error) {
                console.error('Fehler beim Senden der Daten:', error);
            }
        }
    };
      const deleteImage = (imageId) => {
        handleImage(imageId);
    };
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(fetchurl, { activepageid }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const result = response.data;
                setImages(result);
                setCounterImages(result.length);
            } catch (error) {
                console.error("Fehler", error);
            }
        };
        getData();
    }, [images]);

    return (
        <div className={styles.imageframe}>

            <h2>Bilder:</h2>

            {counterImages === 0 ? (
                ""
            ) : counterImages === 1 ? (
                images.map((img) => (
                    <ul className={styles.pagethumbs}>
                        {images.map((img) => (
                            <li key={img.paimg_id}

                            className={`{${img.page_img_isteaser === 1 ? styles.borderlist :''}`}


                            >
                                <img
                                    alt={img.paimg_name}
                                    src={imageurl + "/" + img.paimg_name}
                                    title="Durch klick auf das Bild wird es zum Teaserbild und nicht als Sliderbild verwendet."
                                    onClick={()=>toogleTeaserImage(img.paimg_id)}
                                />
                                <span
                                    data-image-id={img.paimg_id}
                                    className={styles.deleteImage}
                                    onClick={() => deleteImage(img.paimg_id)}
                                >
                  X
                </span>
                            </li>
                        ))}
                    </ul>
                ))
            ) : (
                counterImages > 1 && (
                    <ul className={styles.pagethumbs}>
                        {images.map((img) => (
                            <li key={img.paimg_id} className={`${img.page_img_isteaser === "1" ? styles.borderlist :''}`}>
                                <img
                                    alt={img.paimg_name}
                                    src={imageurl + "/" + img.paimg_name}
                                    title="Durch klick auf das Bild wird es zum Teaserbild und nicht als Sliderbild verwendet."
                                    onClick={()=>toogleTeaserImage(img.paimg_id)}
                                />
                                <span
                                    data-image-id={img.paimg_id}
                                    className={styles.deleteImage}
                                    onClick={() => deleteImage(img.paimg_id)}
                                >
                  X
                </span>
                            </li>
                        ))}
                    </ul>
                )
            )}
        </div>
    );
}

export default Imageloader;
